@tailwind base;
@tailwind components;
@tailwind utilities;

.-rotate-x-90 {
 transform: rotateX(-90deg);
}
.rotate-x-0 {
 transform: rotateX(0deg);
}
.rotate-x-90 {
 transform: rotateX(90deg);
}

.rotate-x-180 {
 transform: rotateX(180deg);
}

.tickLabel, .tick > line {
  fill: #0ABACA!important;
  stroke: #0ABACA!important;
  opacity: 20%;
}

hls-video {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
}

button {
  @apply transition-colors;
}

.pro-gradient {
  @apply bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-600 hover:to-indigo-800
}

.animate-vertical-scroll {
  animation: scroll-bg 30s ease-in-out infinite;
}
@keyframes scroll-bg {
  10% {
    background-position: 0 0%;
  }
  90% {
    background-position: 0 100%;
  }
  95% {
    background-position: 0 100%;
  }
}
